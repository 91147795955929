import { mapActions, mapState } from "vuex";
import { showError } from '@/helpers/globalHelpers';

export default {
    name: "AssignUserDialog",
    data() {
        return {
            addUserForm: {
                user_additional: null,
                ticket_id: null,
                description: null
            },
            responsablesRules: [(v) => !!v || 'El usuario es requerido'],
            loadingSave: false,
            listError: [],
        }
    },

    props: {
        value: Boolean,
        item: Object,
    },

    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        ...mapState({
            responsibles: (state) => state.security.usersActive,
        }),
    },

    created() {
        this.getUsersActive()
    },

    methods: {
        ...mapActions('security', ['getUsersActive']),
        // ...mapActions('assetAssign', ['addSignerUserAssetAssign']),
        // ...mapActions('assetAssignment',['addSignerUserAssetAssignment']),

        ...mapActions('ticket',['addUser']),

        ...mapActions('ticketComment', [
			'listTicketComments',
		]),

        async getTicketComments() {
			const payload = {
				company_id: this.item?.company_id,
				ticket_attention: this.item?.ticket_attention.id,
			};
			const { error } = await this.listTicketComments(payload);
            if(error){
                showError(error);
            }
		},

        async addSignerUser() {
            this.loadingSave = true;
            this.addUserForm.ticket_id = this.item?.id;

            const { error } = await this.addUser(this.addUserForm);

            if (error) showError(error);
            await this.getTicketComments();
            await this.$emit('ticket');

            this.addUserForm = {
                user_additional: null,
                ticket_id: null,
                description: null
            }

            this.loadingSave = false;
            this.dialog = false;
        },

        close() {
            this.dialog = false;
            (this.addUserForm = {
                user_additional: null,
                ticket_id: null,
                description: null
            }),
                (this.listError = []);
            this.$refs?.form?.resetValidation();
        },

        customFilter(item,queryText, itemText) {
			const searchTerms = queryText.toLowerCase().split(' ');
			const fullName = itemText.toLowerCase();

			return searchTerms.every(term => fullName.includes(term));
		},

    },
};
