<template>
	<ticket-detail />
</template>
<script>
import TicketDetail from '@/components/request/TicketDetail/TicketDetail.vue';

export default {
	name: 'RequestDetailPage',
	components: { TicketDetail },
};
</script>
